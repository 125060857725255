import { styled } from "@fluentui/react";
import { getStyles } from "./teamsImage.styles";
import { ITeamsImagePropsStyles, ITeamsImageStyles, ITeamsImageProps } from "./teamsImage.types";
import { TeamsImageBase } from "./teamsImage.base";

const TeamsImage = styled<ITeamsImageProps, ITeamsImagePropsStyles, ITeamsImageStyles>(
    TeamsImageBase,
    getStyles,
    undefined,
    { scope: 'TeamsImage' }
);

export default TeamsImage;