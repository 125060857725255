import { styled } from "@fluentui/react";
import { getStyles } from "./singleStep.styles";
import { ISingleStepPropsStyles, ISingleStepStyles, ISingleStepProps } from "./singleStep.types";
import { SingleStepBase } from "./singleStep.base";

const SingleStep = styled<ISingleStepProps, ISingleStepPropsStyles, ISingleStepStyles>(
    SingleStepBase,
    getStyles,
    undefined,
    { scope: 'SingleStep' }
);

export default SingleStep;