import { styled } from "@fluentui/react";
import { getStyles } from "./advancedDropdown.styles";
import { IAdvancedDropdownProps, IAdvancedDropdownPropsStyles, IAdvancedDropdownStyles } from "./advancedDropdown.types";
import { AdvancedDropdownBase } from "./advancedDropdown.base";

const AdvancedDropdown = styled<IAdvancedDropdownProps, IAdvancedDropdownPropsStyles, IAdvancedDropdownStyles>(
    AdvancedDropdownBase,
    getStyles,
    undefined,
    { scope: 'AdvancedDropdown' }
);

export default AdvancedDropdown;