import { styled } from "@fluentui/react";
import { HeaderBase } from "./header.base";
import { getStyles } from "./header.styles";
import { IHeaderProps, IHeaderPropsStyles, IHeaderStyles } from "./header.types";

const Header = styled<IHeaderProps, IHeaderPropsStyles, IHeaderStyles>(
    HeaderBase,
    getStyles,
    undefined,
    { scope: 'Header' }
);

export default Header;