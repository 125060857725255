import { styled } from "@fluentui/react";
import { DatePickerBase } from "./datePicker.base";
import { IDatePickerProps, IDatePickerPropsStyles, IDatePickerStyles } from "./datePicker.types";
import { getStyles } from "./datePicker.style";

const DatePicker = styled<IDatePickerProps, IDatePickerPropsStyles, IDatePickerStyles>(
    DatePickerBase,
    getStyles,
    undefined,
    { scope: 'Carousel' }
);

export default DatePicker;