import { styled } from "@fluentui/react";
import { getStyles } from "./panel.styles";
import { IPanelPropsStyles, IPanelStyles, IPanelProps } from "./panel.types";
import { PanelBase } from "./panel.base";

const Panel = styled<IPanelProps, IPanelPropsStyles, IPanelStyles>(
    PanelBase,
    getStyles,
    undefined,
    { scope: 'Panel' }
);

export default Panel;