import { AnimationStyles, IStyleFunction } from "@fluentui/react";
import { IMultiStepPropsStyles, IMultiStepStyles, StepMutation } from "./multiStep.types";

export const MultiStepGlobalClassNames = {
    root: 'edi-multi-step-root',
    animation: 'edi-multi-step-animation',
}

export const getStyles: IStyleFunction<IMultiStepPropsStyles, IMultiStepStyles> = (props: IMultiStepPropsStyles): IMultiStepStyles => {
    return {
        root: [
            MultiStepGlobalClassNames.root,
            {
                height: '100%',
                width: '100%',
                overflow: 'hidden'
            }
        ],
        animation: [
            MultiStepGlobalClassNames.animation,
            props.stepMutation === StepMutation.exitPrevious && { ...AnimationStyles.slideLeftOut400, animationDuration: "200ms", animationTimingFunction: 'ease-in' },
            props.stepMutation === StepMutation.enterNext && { ...AnimationStyles.slideLeftIn400 },
            props.stepMutation === StepMutation.exitNext && { ...AnimationStyles.slideRightOut400 },
            props.stepMutation === StepMutation.enterPrevious && { ...AnimationStyles.slideRightIn400 },
            props.stepMutation === StepMutation.fadeOnLoad && { ...AnimationStyles.fadeIn200 },
            {
                height: '100%',
                width: '100%',
                overflow: props.hideScrollbarBody ? 'hidden' : 'auto'
            }
        ]
    };
}