import { IStyleFunction } from "@fluentui/react"
import { IWorkFlowDetailsPagePropsStyles, IWorkFlowDetailsPageStyles } from "./workFlowDetailsPage.types"

export const WorkFlowDetailsPageGlobalClassNames = {
    root: 'edi-work-flow-details-page-root'
}

export const getStyles: IStyleFunction<IWorkFlowDetailsPagePropsStyles, IWorkFlowDetailsPageStyles> = (props: IWorkFlowDetailsPagePropsStyles): IWorkFlowDetailsPageStyles => {
    return {
        root: [
            WorkFlowDetailsPageGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}