import { styled } from "@fluentui/react";
import { getStyles } from "../ediModal/ediModal.styles";
import { IEdiModalPropsStyles, IEdiModalStyles } from "../ediModal/ediModal.types";
import { MultiStepModalBase } from "./multiStepModal.base";
import { IMultiStepModalProps } from "./multiStepModal.types";

const MultiStepModal = styled<IMultiStepModalProps, IEdiModalPropsStyles, IEdiModalStyles>(
    MultiStepModalBase,
    getStyles,
    undefined,
    { scope: 'MultiStepModal' }
);

export default MultiStepModal;