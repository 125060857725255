import { IStyleFunction } from "@fluentui/react"
import { IHeaderPropsStyles, IHeaderStyles } from "./header.types"

export const HeaderGlobalClassNames = {
    root: 'edi-header-root',
    title: 'edi-header-title',
}

export const getStyles: IStyleFunction<IHeaderPropsStyles, IHeaderStyles> = (props: IHeaderPropsStyles): IHeaderStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            HeaderGlobalClassNames.root,
            {
                paddingLeft: '2rem',
                paddingRight: '1.2rem',
                height: '6.05rem',
                borderBottom: `1px solid ${palette.neutralQuaternaryAlt}`
            }
        ],
        title: [ 
            HeaderGlobalClassNames.title,
            {
                marginRight: 5,
                display: 'flex',
                alignItems: 'center',

                img: {
                    width: "3.5rem",
                    marginRight: "1.2rem",
                    marginTop: "0.2rem"
                },
                h2: {
                    color: palette.black,
                    fontSize: '18px',
                    margin: 0,
                }
            }
        ],
        subComponentStyles: {
            pivot: {
                root: {},
                link: {
                    height: '6rem',
                    paddingTop: '0.5rem',
                    ':disabled': {
                        pointerEvents: 'none'
                    }
                },
                linkIsSelected: {},
                linkContent: {},
                text: {},
                count: {},
                icon: {},
                linkInMenu: {},
                overflowMenuButton: {}
            }
        }
    };
}