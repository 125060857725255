/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { CompactPeoplePicker, Label } from "@fluentui/react";
import { IPeoplePickerCustomProps } from "./peoplePicker.types";

//const getClassname = classNamesFunction<IPeoplePickerCustomPropsStyles, IPeoplePickerCustomStyles>();

export const PeoplePickerBase = (props: IPeoplePickerCustomProps) => {
    const [required, setRequired] = useState(props.isRequired);

    useEffect(() => {
        if(props.isRequired){
            if(props.isRequired && props.selectedItems && props.selectedItems?.length < 1)
                setRequired(true);
            else
                setRequired(false);
        }
    }, [props.selectedItems, props.isRequired])

    return (
        <>
            <div style={{display: 'flex', color: 'rgb(164, 38, 44)', alignItems: 'center' }}>
                <Label>{props.title}</Label>
                { required && 
                    <div style={{paddingLeft: '5px'}}>*</div>
                }
            </div>
            
            <CompactPeoplePicker
                styles={props.stylePeoplePicker}
                onResolveSuggestions={props.onResolveSuggestion}
                pickerSuggestionsProps={props.pickerSuggestionsProps}
                resolveDelay={props.resolveDelay}
                selectedItems={props.selectedItems}
                onChange={props.onChange}
                inputProps={
                    { placeholder: props.placeholder }
                }

            />
            { (required || props.customError) && 
                <div style={{color: 'rgb(164, 38, 44)', fontSize: '12px', paddingTop: '5px'}}>{required && props.errorMessage} {props.customError}</div>}
        </>
    );
}