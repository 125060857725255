import { styled } from "@fluentui/react";
import { getStyles } from "./titleListSection.styles";
import { ITitleListSectionPropsStyles, ITitleListSectionStyles, ITitleListSectionProps } from "./titleListSection.types";
import { TitleListSectionBase } from "./titleListSection.base";

const TitleListSection = styled<ITitleListSectionProps, ITitleListSectionPropsStyles, ITitleListSectionStyles>(
    TitleListSectionBase,
    getStyles,
    undefined,
    { scope: 'TitleListSection' }
);

export default TitleListSection;