import { styled } from "@fluentui/react";
import { getStyles } from "./fileIconCell.styles";
import { IFileIconCellPropsStyles, IFileIconCellStyles, IFileIconCellProps } from "./fileIconCell.types";
import { FileIconCellBase } from "./fileIconCell.base";

const FileIconCell = styled<IFileIconCellProps, IFileIconCellPropsStyles, IFileIconCellStyles>(
    FileIconCellBase,
    getStyles,
    undefined,
    { scope: 'FileIconCell' }
);

export default FileIconCell;