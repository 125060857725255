import { IStyleFunction } from "@fluentui/react"
import { ITeamsButtonPropsStyles, ITeamsButtonStyles } from "./teamsButton.types"

export const TeamsButtonGlobalClassNames = {
    root: 'ewe-teams-button-root',
    title: 'ewe-teams-button-title',
    text: 'ewe-teams-button-text',
    subtitle: 'ewe-teams-button-subtitle'
}

export const getStyles: IStyleFunction<ITeamsButtonPropsStyles, ITeamsButtonStyles> = (props: ITeamsButtonPropsStyles): ITeamsButtonStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            TeamsButtonGlobalClassNames.root,{
                background: palette.white,
                borderRadius: "0.4rem",
                boxShadow: "0 0.16rem 0.36rem 0 rgb(0 0 0 / 13%), 0 0.03rem 0.09rem 0 rgb(0 0 0 / 11%)",
                cursor: "pointer",
                flex: '1 1 0',
                minHeight: "10.4rem",
                border: "none",
                alignItems: "center",
                display: "flex",
                ':hover': {
                    backgroundColor: palette.neutralLight,
                },
                margin: '10px'
            }  
        ],
        text: [
            TeamsButtonGlobalClassNames.text, {
                textAlign: 'left',
                width: '100%',
                margin: '3.2rem 0',
                wordBreak: 'break-all',
            }
        ],
        subtitle: [
            TeamsButtonGlobalClassNames.subtitle, {
                color: palette.neutralPrimary,
                margin: 0,
                lineHeight: '1.30',
            }
        ],
        title: [
            TeamsButtonGlobalClassNames.text, {
                fontWeight: 600,
                lineHeight: '2rem',
                fontSize: '1.8rem',
                margin: 0,
                marginBottom: '0.5rem',
            }
        ]
    };
}