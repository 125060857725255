import { styled } from "@fluentui/react";
import { getStyles } from "./notificationPanel.styles";
import { INotificationPanelPropsStyles, INotificationPanelStyles, INotificationPanelProps } from "./notificationPanel.types";
import { NotificationPanelBase } from "./notificationPanel.base";

const NotificationPanel = styled<INotificationPanelProps, INotificationPanelPropsStyles, INotificationPanelStyles>(
    NotificationPanelBase,
    getStyles,
    undefined,
    { scope: 'NotificationPanel' }
);

export default NotificationPanel;