import { classNamesFunction, IconButton, IIconProps, Modal, ResponsiveMode } from "@fluentui/react";
import React from "react";
import { IEdiModalProps, IEdiModalPropsStyles, IEdiModalStyles } from "./ediModal.types";

const getClassNames = classNamesFunction<IEdiModalPropsStyles, IEdiModalStyles>();

const initialIconProps: IIconProps = {
    iconName: 'Cancel'
}

export const EdiModalBase = (props: IEdiModalProps) => {
    const classNames = getClassNames(props.styles, {
        theme: props.theme,
        className: props.className,
        styles: props.styles,
        width: props.width,
        height: props.height
    });

    return (
        <Modal
            isOpen={props.isOpen}
            isBlocking={props.isBlocking}
            className={props.className}
            containerClassName={classNames.container}
            responsiveMode={ResponsiveMode.large}
            scrollableContentClassName={classNames.content}
            onDismissed={props.onDismissed}     
        >
            {!props.hideHeader && !props.bodyOnly &&
            <div>
                <div className={classNames.header}>
                    <div className={classNames.title}>{props.title}</div>
                    {props.showCloseIcon &&
                        <div className={classNames.closeIcon}>
                            <IconButton
                                styles={classNames.subComponentStyles.iconButton()}
                                iconProps={{ ...initialIconProps, ...props.closeIconProps }}
                                onClick={props.onCloseClick}
                            />
                        </div>
                    }
                </div>
                <div className={classNames.subTitle}>{props.subtitle}</div>
                {props.headerCustom}
            </div>
                
            }
            <div className={props.bodyClassname ? props.bodyClassname : classNames.body}>
                <div className={classNames.bodyContent}>
                    {props.body}
                </div>
            </div>
            {props.footer && !props.bodyOnly && <div className={classNames.footer}>{props.footer}</div>}
        </Modal>
    );
}