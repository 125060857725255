import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import * as Config from '../authentication/config';
import { environment } from "./config";

const browserHistory = createBrowserHistory({ basename: '' });
const appInsightReactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: environment[window.location.host].connectionString,
        appId: Config.environment[window.location.host].clientId,
        enableCorsCorrelation: true,
        extensions: [appInsightReactPlugin],
        extensionConfig: {
            [appInsightReactPlugin.identifier]: { history: browserHistory }
        }
    }
});

appInsights.loadAppInsights();

export { appInsightReactPlugin, appInsights, browserHistory };