import { styled } from "@fluentui/react";
import { getStyles } from "./carousel.styles";
import { ICarouselPropsStyles, ICarouselStyles, ICarouselProps } from "./carousel.types";
import { CarouselBase } from "./carousel.base";

const Carousel = styled<ICarouselProps, ICarouselPropsStyles, ICarouselStyles>(
    CarouselBase,
    getStyles,
    undefined,
    { scope: 'Carousel' }
);

export default Carousel;