import { IStyleFunction } from "@fluentui/react"
import { IWorkflowApprovalModalPropsStyles, IWorkflowApprovalModalStyles } from "./workflowApprovalModal.types"

export const WorkflowApprovalModalGlobalClassNames = {
    root: 'edi-workflow-approval-modal-root'
}

export const getStyles: IStyleFunction<IWorkflowApprovalModalPropsStyles, IWorkflowApprovalModalStyles> = (props: IWorkflowApprovalModalPropsStyles): IWorkflowApprovalModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            WorkflowApprovalModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
    subComponentStyles:{
        textFieldStyle: {
            root: {
                width: 250
            },
            wrapper:{              
            },
            field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.neutralPrimary } } },                       
            fieldGroup: {
            },
            errorMessage: {
            }
        }
    }
    }
}