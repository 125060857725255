import { styled } from "@fluentui/react";
import { getStyles } from "./workFlowDetailsPage.styles";
import { IWorkFlowDetailsPagePropsStyles, IWorkFlowDetailsPageStyles, IWorkFlowDetailsPageProps } from "./workFlowDetailsPage.types";
import { WorkFlowDetailsPageBase } from "./workFlowDetailsPage.base";

const WorkFlowDetailsPage = styled<IWorkFlowDetailsPageProps, IWorkFlowDetailsPagePropsStyles, IWorkFlowDetailsPageStyles>(
    WorkFlowDetailsPageBase,
    getStyles,
    undefined,
    { scope: 'WorkFlowDetailsPage' }
);

export default WorkFlowDetailsPage;