import { styled } from "@fluentui/react";
import { getStyles } from "./accordion.styles";
import { IAccordionPropsStyles, IAccordionStyles, IAccordionProps } from "./accordion.types";
import { AccordionBase } from "./accordion.base";

const Accordion = styled<IAccordionProps, IAccordionPropsStyles, IAccordionStyles>(
    AccordionBase,
    getStyles,
    undefined,
    { scope: 'Accordion' }
);

export default Accordion;