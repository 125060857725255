import { styled } from "@fluentui/react";
import { getStyles } from "./uploadButton.styles";
import { IUploadButtonPropsStyles, IUploadButtonStyles, IUploadButtonProps } from "./uploadButton.types";
import { UploadButtonBase } from "./uploadButton.base";

const UploadButton = styled<IUploadButtonProps, IUploadButtonPropsStyles, IUploadButtonStyles>(
    UploadButtonBase,
    getStyles,
    undefined,
    { scope: 'UploadButton' }
);

export default UploadButton;