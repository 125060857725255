import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";

export enum StepMutation {
    none = 0,
    exitPrevious = 1,
    enterPrevious = 2,
    exitNext = 3,
    enterNext = 4,
    fadeOnLoad = 5,
}

export interface IStepData {
    content: JSX.Element;
}

export interface IMultiStepProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IMultiStepPropsStyles, IMultiStepStyles>;
    className?: string;
    activeStep: number;
    steps: IStepData[];
    animateInitialStep?: boolean;
    onStepExit?: (isMutating: boolean) => void;
    onStepEnter?: (isMutating: boolean) => void;
    onStepChange?: (isMutating: boolean) => void;
    hideScrollbar?: boolean;
}

export interface IMultiStepPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IMultiStepPropsStyles, IMultiStepStyles>;
    className?: string;
    stepMutation: StepMutation;
    hideScrollbarBody?: boolean;
}

export interface IMultiStepStyles {
    root: IStyle;
    animation: IStyle;
}