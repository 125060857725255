import { IStyleFunction } from "@fluentui/react"
import { INotificationPanelPropsStyles, INotificationPanelStyles } from "./notificationPanel.types"

export const NotificationPanelGlobalClassNames = {
    root: 'edi-notification-panel-root',
    cardContainer: 'edi-notification-panel-cardContainer',
    errorTitle: 'edi-notification-panel-errorTitle',
    errorMessage: 'edi-notification-panel-errorMessage',
    successTitle: 'edi-notification-panel-successTitle',
    successMessage: 'edi-notification-panel-successMessage',
}

export const getStyles: IStyleFunction<INotificationPanelPropsStyles, INotificationPanelStyles> = (props: INotificationPanelPropsStyles): INotificationPanelStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [NotificationPanelGlobalClassNames.root,
        {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            paddingBottom: '15px',
            borderBottom: '1px solid ' + palette.themePrimary
        }],
        cardContainer: [NotificationPanelGlobalClassNames.cardContainer,
        {
            display: 'flex',
            flexDirection: 'column'
        }],
        errorTitle: [NotificationPanelGlobalClassNames.errorTitle,
        {
            color: palette.redDark,
            fontWeight: 'bold'
        }],
        errorMessage: [NotificationPanelGlobalClassNames.errorMessage,
        {
            color: palette.redDark
        }],
        successTitle: [NotificationPanelGlobalClassNames.successTitle,
        {
            color: palette.themePrimary,
            fontWeight: 'bold'

        }],
        successMessage: [NotificationPanelGlobalClassNames.successMessage,
        {
            color: palette.themePrimary,
        }],
        subComponentStyles: {
            panelStyle: {
                overlay: {
                    backgroundColor: 'transparent'
                },
                contentInner: {
                    justifyContent: props.noNotifications ? 'center' : '',
                    alignItems: props.noNotifications ? 'center' : '',
                }
            }
        }
    };
}