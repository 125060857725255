/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNamesFunction, DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { IEdiFilePropsStyles, IEdiFileStyles, IEdiFileProps } from "./CopyFileOnEdiModal.types";
import { useTranslation } from "react-i18next";
import { FileShort } from "../../../../models/fileShort";
import EDIFolderContent from "../createWorkflow/ediFile/EDIfolderContent/EDIfolderContent";
import ArchiveEDINavigation from "../createWorkflow/ediFile/EDIShowArchives/archiveEDINavigation";
import { useWorkflowDispatch } from "../../../workflowStore";
import { Call, callsList } from "../../../../common/types/Call";
import { nanoid } from "@reduxjs/toolkit";
import { insertCall, setCall } from "../../../features/callNotification";
import { workflowApi } from "../../../services/workflow.api";
import { workflowDisjointedApi } from "../../../services/workflowDisjointed/workflowDisjointed.api";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
//import DocumentTagsList from "./documentTagsList/documentTagsList";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";

const getClassNames = classNamesFunction<IEdiFilePropsStyles, IEdiFileStyles>();

export type SavePageData = {
    folder: number;
}

export type inputFileMetaData = {
  id: number;
  metaData: string|null;
}

enum UploadFileSteps {
    chooseArchive = 0,
    end = 1,
    error = 2
  }
  
  enum ScheduleFileSteps {
    chooseArchive = 0,
    chooseTags = 1,
    end = 2,
    error = 3
  }

export const CopyFileOnEdiModalBase = (props: IEdiFileProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['uploadFileModal','copyOnEdi', 'common']);
    const [showTeams, setShowTeams] = useState(true);
    const [currentTeam, setCurrentTeam] = useState<FileShort | undefined>(undefined);
    const dispatch = useWorkflowDispatch();
    const [genericError]=useState('common:genericErrorApi');
    const [selectedFolder,setSelectedFolder]=useState(0);
    const [currentStep, setCurrentStep] = useState(UploadFileSteps.chooseArchive);
    const [currentScheduleStep, setCurrentScheduleStep] = useState(ScheduleFileSteps.chooseArchive);
    const [archive, setArchive]=useState<FileShort>(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const error = t('common:GenericErrorApi');
    const unathorized = t('copyOnEdi:notPermissions')

    const copyDisjointedOnEdi = useCallback(async () => {
        const saveWorkflowCall: Call = {
          type: callsList.saveWorkflow,
          nameOperation: t('copyOnEdi:saveWorkflowOperation'),
          errors: [
              { code: 403, message: unathorized },
              { code: 500, message: error}
          ]
        }
        const id = nanoid();
        const payload = { requestId: id, notification: saveWorkflowCall }
        dispatch(insertCall(payload));
        const inputs : inputFileMetaData[] = [];
        props.selectedWorkfows.forEach(s=> {
          let metadata: string | null = `{"documentType": "${s.documentType}"}`;
            if(s.documentType === undefined || s.documentType === null)
              metadata = null;
          inputs.push({id:s.id,metaData:metadata})
        })
        try {
            const i = await workflowDisjointedApi.uploadFilesToEdi(inputs,selectedFolder);
            const genericPayload = i.errorCount===0 ? 
                { requestId: id, success: true, message: `${t('copyOnEdi:savedWorkflowsSuccess')} ${i.successCount}, Tot: ${i.totalCount}`} : 
                { requestId: id, success: false, message: `${t('copyOnEdi:savedWorkflowsSuccess')} ${i.successCount}, ${t('copyOnEdi:savedWorkflowsError')} ${i.errorCount}, Tot: ${i.totalCount}`}
            dispatch(setCall(genericPayload));
            props.reloadList();
        }
        catch (error) {
            const failurePayload = { requestId: id, success: false, message: t(genericError) }
            dispatch(setCall(failurePayload));
            props.reloadList();
        }
    },[dispatch, genericError, props, selectedFolder,t,error,unathorized])

    const copyLinearOnEdi = useCallback(async () => {
        const saveWorkflowCall: Call = {
            type: callsList.saveWorkflow,
            nameOperation: t('copyOnEdi:saveWorkflowOperation'),
            errors: [
                { code: 403, message: unathorized },
                { code: 500, message: error}
            ]
        }
        const id = nanoid();
        const payload = { requestId: id, notification: saveWorkflowCall }
        dispatch(insertCall(payload));
        try {
            let metadata: string | null = `{"documentType": "${props.selectedWorkfows[0].documentType}"}`;
            if(props.selectedWorkfows[0].documentType === undefined || props.selectedWorkfows[0].documentType === null)
              metadata = null;
            await workflowApi.uploadToEdi(props.selectedWorkfows[0] ? props.selectedWorkfows[0].id : 0,selectedFolder,metadata);
            const successPayload = { requestId: id, success: true, message: t('copyOnEdi:savedWorkflow') }
            dispatch(setCall(successPayload));
            props.reloadList();
        }
        catch (error) {
            const failurePayload = { requestId: id, success: false, message: t(genericError) }
            dispatch(setCall(failurePayload));
            props.reloadList();
        }
    },[dispatch, genericError, props, selectedFolder,t,error,unathorized])

    const copyDocsOnEdi = useCallback(async () => {
      const documentCall: Call = {
        type: callsList.saveDocument,
        nameOperation: t('copyOnEdi:saveDocumentsOperation'),
        errors: [
            { code: 403, message: t('copyOnEdi:notPermissions') },
            { code: 500, message: t('common:GenericErrorApi')}
        ]
    }
    const iddoc = nanoid();
    const payloaddoc = { requestId: iddoc, notification: documentCall }
        console.log(props.selectedWorkfows)
        dispatch(insertCall(payloaddoc));
        try {
            const inputs = props.selectedDocuments ? props.selectedDocuments.map(w => {return {metaData: w?.documentType, id: w?.stepDocumentId} as inputFileMetaData}) : []
            inputs.forEach(s=> {
              let metadata: string | null = `{"documentType": "${s.metaData}"}`;
                if(s.metaData === undefined || s.metaData === null)
                  metadata = null;
              s.metaData = metadata;
            })
            const i = await workflowApi.uploadDocsToEdi(props.selectedWorkfows? props.selectedWorkfows[0].id : 0 ,
              inputs,
              selectedFolder);
            const genericPayload = i.errorCount===0 ? 
                { requestId: iddoc, success: true, message: `${t('copyOnEdi:savedDocuments')} ${i.successCount}, Tot: ${i.totalCount}`} : 
                { requestId: iddoc, success: false, message: `${t('copyOnEdi:savedDocuments')} ${i.successCount}, ${t('copyOnEdi:errorDocs')} ${i.errorCount}, Tot: ${i.totalCount}`}
            dispatch(setCall(genericPayload));
            props.reloadList();
        }
        catch (error) {
            const failurePayload = { requestId: iddoc, success: false, message: t(genericError) }
            dispatch(setCall(failurePayload));
            props.reloadList();
        }
    },[dispatch, genericError, props, selectedFolder,t])

    useEffect(() => {
        setSelectedFolder(currentTeam?.rootFolderId??0);
    }, [currentTeam]); //eslint-disable-line react-hooks/exhaustive-deps

    const onLevelUp = () => {
        if(currentTeam?.isArchive){
          setArchive(undefined);
        }
        setCurrentTeam(undefined);
        setShowTeams(true);
    }

    const onLevelDown = (team: FileShort) => {
        setShowTeams(false);
        setCurrentTeam(team);
        if(team.isArchive){
          setArchive(team);
        }
    }
    const firstPageBodyModal = () => {
    return (
                <div>
                    <div>
                        <Stack>
                            {showTeams
                                ?
                                <ArchiveEDINavigation interaction onTeamSelected={onLevelDown} onError={()=>console.log("Error")}/>
                                :
                                <EDIFolderContent
                                    isError={(err) => console.log(err)}
                                    maxNumFiles={1}
                                    disableFileSelection
                                    disableFolderSelection
                                    onSelectionChange={() => console.log("Void")}
                                    selectAllEnabled={false}
                                    onLevelUp={onLevelUp}
                                    selectedTeam={currentTeam}
                                    onFolderSelection={(folder)=>{setSelectedFolder(folder);}}
                                />
                            }
                        </Stack>
                    </div>             
                </div>
    );}

    const execute = useCallback(async () => {
      props.selectedDocuments?.length !== 0 ? copyDocsOnEdi() : props.disjointed ? copyDisjointedOnEdi() : copyLinearOnEdi(); setCurrentStep(UploadFileSteps.end); setCurrentScheduleStep(ScheduleFileSteps.end)
    }, [copyDocsOnEdi, copyDisjointedOnEdi, copyLinearOnEdi, setCurrentStep, props]);

    
    const secondPageBodyModal = () => {
      return (<>
      <div className={classNames.wellDoneImage}>
        <TeamsImage imageName={ImageName.InProgress} style={{ width: '30%' }} />
          <span style={{fontSize: 24,fontWeight: 600}}>{t('common:requestSent')}</span>
        <PrimaryButton text={t('common:leave')} onClick={()=>props.closeModal()}style={{ flex: '0 0 auto' }} />
        </div>
                </>)
    }
/*
    const advancedSecondPageBodyModal = () => {
        return(
          <DocumentTagsList
            shortJsonSchema={archive?.metadataShortJsonSchema}
            workflows={props.selectedDocuments?.length === 0 ? props.selectedWorkfows : []}
            documents={props.selectedDocuments?.length === 0 ? [] : props.selectedDocuments}
          />
        );
      }
*/
      const footer = useMemo(() => {
        return (
          <div className={classNames.subfirstFooterContainer}>
            <div className={classNames.saveFooterContainer}>
                    <div className={classNames.saveButtonContainer}>
                        <DefaultButton
                          text={t('common:undoEliminate')} 
                          onClick={() => {props.closeModal();}}
                        />
                        <PrimaryButton 
                          style={{marginLeft:20}}
                          disabled={selectedFolder.valueOf()===0 || props.selectedWorkfows.length < 1}
                          text={t("footer.confirm")}
                          onClick={ () => execute()}
                        />
                        </div>
                    </div>
          </div>
        )
      }, [props, execute, selectedFolder, classNames, t]);

      const errorfooter = useMemo(() => {
        return (
          <div className={classNames.footer}>
            <PrimaryButton
              text={t('common:close')}
              onClick={()=>props.closeModal()}
            />
          </div>
        );
      }, [props, t, classNames.footer]);

      const errorPageBodyModal = () => {
        return (
          <div className={classNames.secondFooterContainer}>
            <TeamsImage
              imageName={ImageName.Error1}
              className={classNames.wellDoneImage}
              fullContainer scale={0.8}
            />
          </div>
        )
      }
/*
  const advancedFooter = useMemo(() => {
    return (
      <div className={classNames.firstFooterContainer}>
        <DefaultButton text={t('footer.back')}
          style={{ marginRight: '2px' }}
          allowDisabledFocus
          onClick={() => { 
            currentScheduleStep === ScheduleFileSteps.chooseTags ?
                setCurrentScheduleStep(ScheduleFileSteps.chooseArchive) : props.closeModal();
            }
          }
        />
        <PrimaryButton text={t('footer.confirm')}
          allowDisabledFocus
          onClick={() => { 
            switch(currentScheduleStep)
            {
              case ScheduleFileSteps.chooseArchive:
                setCurrentScheduleStep(ScheduleFileSteps.chooseTags);
                break;
              case ScheduleFileSteps.chooseTags:
                execute();
                break;
              default:
                setCurrentScheduleStep(ScheduleFileSteps.error);
                break;
            }
          }}
          disabled={props.selectedWorkfows.length < 1}
        />
      </div>
    )
  }, [props,classNames.firstFooterContainer, execute, currentScheduleStep, t]); 
*/
  const steps = [
    {
      title: t('title'),
      body: firstPageBodyModal(),
      footer: footer,
    },
    {
      bodyOnly: true,
      body: secondPageBodyModal()
    },
    {
      title: t('common:error'),
      body: errorPageBodyModal(),
      footer: errorfooter
    }
  ];

  /*
  const scheduleSteps = [
    {
      title: t('title'),
      body: firstPageBodyModal(),
      footer: advancedFooter,
    },
    {
      title: t('tags'),
      body: advancedSecondPageBodyModal(),
      footer: advancedFooter
    },
    {
      bodyOnly: true,
      body: secondPageBodyModal()
    },
    {
      title: t('common:error'),
      body: errorPageBodyModal(),
      footer: errorfooter
    }
  ];
*/
  return (
    <>
        <MultiStepModal
          subtitle={props.disjointed && props.selectedWorkfows.length > 5 ? t('subtitle') : t('')}
          isOpen={props.saveModalShown}
          showCloseIcon={currentStep !== UploadFileSteps.error || currentScheduleStep !== ScheduleFileSteps.error}
          onCloseClick={props.closeModal}
          //steps={archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null ? steps : scheduleSteps}
          steps={steps}
          //activeStep={archive?.metadataShortJsonSchema === undefined || archive?.metadataShortJsonSchema === null ? currentStep : currentScheduleStep}
          activeStep={currentStep}
          height={500}
          width={750}
          hideScrollbarBody
          animateInitialStep
        />
    </>
  );
}