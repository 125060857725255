import { IStyleFunction } from "@fluentui/react"
import { ITeamsPropsStyles, ITeamsStyles } from "./teams.types"

export const TeamsGlobalClassNames = {
    root: 'edi-teams-root'
}

export const getStyles: IStyleFunction<ITeamsPropsStyles, ITeamsStyles> = (props: ITeamsPropsStyles): ITeamsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            TeamsGlobalClassNames.root,
            {
                height: '100%',
                '*::-webkit-scrollbar': {
                    width: 16,
                    height: 16
                },
                '*::-webkit-scrollbar-thumb': {
                    border: '5px solid transparent',
                    backgroundClip: 'padding-box',
                    backgroundColor: 'transparent',
                    borderRadius: 25
                },
                '*:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.neutralSecondary// 'rgba(66, 66, 66, 0.3)'
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    border: '2px solid transparent'
                }
            }
        ]
    };
}