import { styled } from "@fluentui/react";
import { getStyles } from "./workflowDetails.styles";
import { IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles, IWorkflowDetailsProps } from "./workflowDetails.types";
import { WorkflowDetailsBase } from "./workflowDetails.base";

const WorkflowDetails = styled<IWorkflowDetailsProps, IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles>(
    WorkflowDetailsBase,
    getStyles,
    undefined,
    { scope: 'WorkflowDetails' }
);

export default WorkflowDetails;