import { classNamesFunction } from "@fluentui/react";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { IMultiStepProps, IMultiStepPropsStyles, IMultiStepStyles, StepMutation } from "./multiStep.types";

const getClassNames = classNamesFunction<IMultiStepPropsStyles, IMultiStepStyles>();

export const MultiStepBase: React.FC<IMultiStepProps> = (props: IMultiStepProps) => {
    const [currentStep, setCurrentStep] = useState(props.activeStep);
    const [stepMutation, setStepMutation] = useState(props.animateInitialStep ? StepMutation.fadeOnLoad : StepMutation.none);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, stepMutation: stepMutation, hideScrollbarBody: props.hideScrollbar });

    useEffect(() => {
        if (props.activeStep === currentStep)
            return;

        const callbacks = { onStepChange: noop, onStepEnter: noop, onStepExit: noop, ...props };

        let startAnimation = StepMutation.exitPrevious, endAnimation = StepMutation.enterNext;
        if (props.activeStep < currentStep) {
            startAnimation = StepMutation.exitNext;
            endAnimation = StepMutation.enterPrevious;
        }
        callbacks.onStepChange(true);
        callbacks.onStepExit(true);

        setStepMutation(startAnimation);
        setTimeout(() => {
            setCurrentStep(props.activeStep);
            setStepMutation(endAnimation);
            callbacks.onStepExit(false);
            callbacks.onStepEnter(true);
        }, 400);

        setTimeout(() => {
            setStepMutation(StepMutation.none);
            callbacks.onStepEnter(false);
            callbacks.onStepChange(false);
        }, 800);
    }, [currentStep, props, props.activeStep]);

    return (
        <div className={classNames.root}>
            <div className={classNames.animation}>
                {props.steps[currentStep]?.content}
            </div>
        </div>
    );
}