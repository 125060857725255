import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { carouselItem } from "./carousel.base";


export enum StepMutation {
    none = 0,
    exitPrevious = 1,
    enterPrevious = 2,
    exitNext = 3,
    enterNext = 4,
    fadeOnLoad = 5,
}


export interface ICarouselProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICarouselPropsStyles, ICarouselStyles>;
    className?: string;
    // ADD COMPONENT PROPS HERE
    activeStep: number;
    steps?: JSX.Element[];
    animateInitialStep?: boolean;
    pageCount: number;
    next?: ()=>void;
    back?: ()=>void;
    onClickItem: (item:carouselItem)=>void;
    onStepExit?: (isMutating: boolean) => void;
    onStepEnter?: (isMutating: boolean) => void;
    onStepChange?: (isMutating: boolean) => void;
    items: carouselItem[];
}

export interface ICarouselPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICarouselPropsStyles, ICarouselStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
    stepMutation: StepMutation;

}

export interface ICarouselStyles {
    root: IStyle; // EXAMPLE CUSTOMIZABLE PROP
    animation: IStyle;
    cardContainer: IStyle;
    disabledCardContainer: IStyle;
    cardTitleContainer: IStyle;
    cardTitleArrow: IStyle;
    cardTitle: IStyle;
    disabledIcon: IStyle;
    cardIconContainer: IStyle;
    cardIcon: IStyle;
    cardDescription: IStyle;
    arrowContainer: IStyle;
    arrowIcon: IStyle;
}