import { IStyleFunction } from "@fluentui/react"
import { IRootPropsStyles, IRootStyles } from "./root.types"

export const RootGlobalClassNames = {
    root: 'edi-doclab-root',
    leftNav: 'edi-doclab-left-nav',
    rightContent: 'edi-doclab-right-content'
}

export const getStyles: IStyleFunction<IRootPropsStyles, IRootStyles> = (props: IRootPropsStyles): IRootStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            RootGlobalClassNames.root,
            {
                height: "calc(100% - 6.05rem)",
                width: "100%"
            }
        ],
        leftNav: [
            RootGlobalClassNames.leftNav,
            {
                background: palette.neutralLight,
                flexBasis: `280px`,
                overflow: 'hidden',
                transition: 'all 200ms ease-in-out',
                '@media(min-width: 1432px)': {  // 1500 - 68
                    flexBasis: "360px"
                },
                '@media(min-width: 1282px)': { // 1350 - 68
                    flexBasis: "320px"
                }
            }
        ],
        rightContent: [
            RootGlobalClassNames.rightContent,
            {
                overflow: 'hidden',
            }
        ]
    };
}