import { IStyleFunction } from "@fluentui/react"
import { INavigationContainerPropsStyles, INavigationContainerStyles } from "./navigationContainer.types"

export const NavigationContainerGlobalClassNames = {
    root: 'edi-navigation-container-root',
    navigationContainer: 'edi-navigation-container-navigationContainer',
    spinner: 'edi-navigation-container-spinner',
    footerContainer: 'edi-navigation-container-footerContainer',
    createArchiveButton: 'edi-navigation-container-createArchiveButton',
    createArchivePlusIcon: 'edi-navigation-container-createArchivePlusIcon'
}

export const getStyles: IStyleFunction<INavigationContainerPropsStyles, INavigationContainerStyles> = (props: INavigationContainerPropsStyles): INavigationContainerStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            NavigationContainerGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        navigationContainer: [NavigationContainerGlobalClassNames.navigationContainer, {
            height: 'calc(100% - 102px);',
            overflow: 'auto',
            selectors: {
                '> *': {
                    position: 'relative'
                },
                '> :not(:last-child)::after': {
                    borderTop: 'solid .2rem #e0e0e0',
                    bottom: 0,
                    content: "''",
                    left: '2rem',
                    pointerEvents: 'none',
                    position: 'absolute',
                    width: 'calc(100% - 4rem)'
                }
            }
        }],
        spinner: [NavigationContainerGlobalClassNames.spinner, {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }],
        footerContainer:[NavigationContainerGlobalClassNames.footerContainer,{
            display: 'flex',
            height: '32px',
            padding: '17px 12px 11px 20px',
            position: 'relative',
            justifyContent: 'space-between',
            selectors: {
                '::after': {
                    borderTop: 'solid .2rem ' + semanticColors.variantBorder,
                    top: 0,
                    content: "''",
                    left: '2rem',
                    pointerEvents: 'none',
                    position: 'absolute',
                    width: 'calc(100% - 4rem)'
                }
            }
        }],
        createArchiveButton:[NavigationContainerGlobalClassNames.createArchiveButton,{
            height: 'inherit',
            color: palette.black,
            selectors: {
                ' .ms-Icon': {
                    marginRight: 15,
                    color: palette.black
                },
                ':active': {
                    color: palette.themePrimary,
                    'span': {
                        position: 'unset'
                    },
                    'i': {
                        color: palette.themePrimary + ' !important'
                    }
                },
                ':hover': {
                    color: palette.themePrimary,
                }
            }
        }],
        createArchivePlusIcon:[NavigationContainerGlobalClassNames.createArchivePlusIcon,{
            position: 'absolute',
            left: 18,
            bottom: 4,
            fontSize: 9,
            backgroundColor: palette.neutralLight
        }],
        subComponentStyles: { 
            reminderIconButton: {
                root: {
                    color: palette.black, 
                },
                iconHovered: { 
                    backgroundColor: 'transparent',
                    color: palette.themePrimary
                }, 
                rootHovered: {
                    backgroundColor: 'transparent',
                },
            },
        }
    };
}