import { styled } from "@fluentui/react";
import { getStyles } from "./memberList.styles";
import { IMemberListPropsStyles, IMemberListStyles, IMemberListProps } from "./memberList.types";
import { MemberListBase } from "./memberList.base";

const MemberList = styled<IMemberListProps, IMemberListPropsStyles, IMemberListStyles>(
    MemberListBase,
    getStyles,
    undefined,
    { scope: 'MemberList' }
);

export default MemberList;