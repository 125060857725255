import { styled } from "@fluentui/react";
import { getStyles } from "./information.styles";
import { IInformationPropsStyles, IInformationStyles, IInformationProps } from "./information.types";
import { InformationBase } from "./information.base";

const Information = styled<IInformationProps, IInformationPropsStyles, IInformationStyles>(
    InformationBase,
    getStyles,
    undefined,
    { scope: 'Information' }
);

export default Information;