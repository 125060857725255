import { IStyleFunction } from "@fluentui/react"
import { IPanelPropsStyles, IPanelStyles } from "./panel.types"

export const PanelGlobalClassNames = {
    root: 'edi-panel-root'
}

export const getStyles: IStyleFunction<IPanelPropsStyles, IPanelStyles> = (props: IPanelPropsStyles): IPanelStyles => {
    return {
        root: [
            PanelGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}