import { styled } from "@fluentui/react";
import { getStyles } from "./stepStatus.styles";
import { IStepStatusPropsStyles, IStepStatusStyles, IStepStatusProps } from "./stepStatus.types";
import { StepStatusBase } from "./stepStatus.base";

const StepStatus = styled<IStepStatusProps, IStepStatusPropsStyles, IStepStatusStyles>(
    StepStatusBase,
    getStyles,
    undefined,
    { scope: 'StepStatus' }
);

export default StepStatus;