import { styled } from "@fluentui/react";
import { getStyles } from "./multiStep.styles";
import { IMultiStepPropsStyles, IMultiStepStyles, IMultiStepProps } from "./multiStep.types";
import { MultiStepBase } from "./multiStep.base";

const MultiStep = styled<IMultiStepProps, IMultiStepPropsStyles, IMultiStepStyles>(
    MultiStepBase,
    getStyles,
    undefined,
    { scope: 'MultiStep' }
);

export default MultiStep;