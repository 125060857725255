import { styled } from "@fluentui/react";
import { getStyles } from "./teamsButton.styles";
import { ITeamsButtonPropsStyles, ITeamsButtonStyles, ITeamsButtonProps } from "./teamsButton.types";
import { TeamsButtonBase } from "./teamsButton.base";

const TeamsButton = styled<ITeamsButtonProps, ITeamsButtonPropsStyles, ITeamsButtonStyles>(
    TeamsButtonBase,
    getStyles,
    undefined,
    { scope: 'TeamsButton' }
);

export default TeamsButton;