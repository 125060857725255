import { styled } from "@fluentui/react";
import { getStyles } from "./choiceInputSource.styles";
import { IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles, IChoiceInputSourceProps } from "./choiceInputSource.types";
import { ChoiceInputSourceBase } from "./choiceInputSource.base";

const ChoiceInputSource = styled<IChoiceInputSourceProps, IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles>(
    ChoiceInputSourceBase,
    getStyles,
    undefined,
    { scope: 'ChoiceInputSource' }
);

export default ChoiceInputSource;