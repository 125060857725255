import { styled } from "@fluentui/react";
import { getStyles } from "./addSteps.styles";
import { IAddStepsPropsStyles, IAddStepsStyles, IAddStepsProps } from "./addSteps.types";
import { AddStepsBase } from "./addSteps.base";

const AddSteps = styled<IAddStepsProps, IAddStepsPropsStyles, IAddStepsStyles>(
    AddStepsBase,
    getStyles,
    undefined,
    { scope: 'AddSteps' }
);

export default AddSteps;