import { IStyleFunction } from "@fluentui/react"
import { IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types"

export const ToolbarGlobalClassNames = {
    root: 'edi-tool-bar-root',
    wrapper: 'edi-tool-bar-wrapper',
    iconWrapper: 'edi-tool-bar-iconWrapper'
}

export const getStyles: IStyleFunction<IToolbarPropsStyles, IToolbarStyles> = (props: IToolbarPropsStyles): IToolbarStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ToolbarGlobalClassNames.root,
            {
                backgroundColor: semanticColors.bodyBackground
            }
        ],
        iconWrapper:[
            ToolbarGlobalClassNames.iconWrapper,
            {
                display: 'flex',
                margin: '0 20px',
                alignItems: 'center',
                cursor: 'pointer',
                fontSize: '20px',
                paddingTop: '3px'
            }
        ],
        wrapper: [
            ToolbarGlobalClassNames.wrapper,
            {
                width: '99%',
                '& .ms-CommandBar': {
                    paddingLeft: 0,
                }
            }
        ],
        subComponentStyles: {
            pivot: {
                root: {
                    height: '100%'
                },
                link: {

                },
                linkIsSelected: {},
                linkContent: {},
                text: {},
                count: {},
                icon: {},
                linkInMenu: {},
                overflowMenuButton: {}
            },
            reloadIconButton: {
                root: {
                    color: palette.black,
                    transform: 'rotate(90deg)'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`,
                    color: palette.themePrimary
                },
                rootExpanded: {
                    backgroundColor: 'transparent',
                    color: palette.themePrimary,
                    textShadow: 'rgb(37 36 35) 0px 0px 1px'
                },
                rootPressed: {
                    backgroundColor: 'transparent',

                },
                rootHovered: {
                    backgroundColor: 'transparent',
                },
                rootDisabled: {
                    backgroundColor: 'transparent'
                }
            },
        }
    };
}
