import { IStyleFunction } from "@fluentui/react"
import { IUploadButtonPropsStyles, IUploadButtonStyles } from "./uploadButton.types"

export const UploadButtonGlobalClassNames = {
    example: 'edi-ExampleClassName' // EXAMPLE CLASS NAME
}

export const getStyles: IStyleFunction<IUploadButtonPropsStyles, IUploadButtonStyles> = (props: IUploadButtonPropsStyles): IUploadButtonStyles => {
    return {
        uploadButton:{  
            selectors: {
                cursor: 'unset',
                ':active': {
                    'span': {
                        position:'unset',
                        left: '0px',
                        right: '0px',
                    }                 
                }
            }
        },   
        inputFile:{              
            position: 'absolute',
            opacity: 0,
            width: '100%',
            height: '100%',
        },  

    };
}