import { styled } from "@fluentui/react";
import { getStyles } from "./workflowApprovalStep.styles";
import { IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles, IWorkflowApprovalStepProps } from "./workflowApprovalStep.types";
import { WorkflowApprovalStepBase } from "./workflowApprovalStep.base";

const WorkflowApprovalStep = styled<IWorkflowApprovalStepProps, IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles>(
    WorkflowApprovalStepBase,
    getStyles,
    undefined,
    { scope: 'WorkflowApprovalStep' }
);

export default WorkflowApprovalStep;