import { styled } from "@fluentui/react";
import { getStyles } from "./workflowApprovalModal.styles";
import { IWorkflowApprovalModalPropsStyles, IWorkflowApprovalModalStyles, IWorkflowApprovalModalProps } from "./workflowApprovalModal.types";
import { WorkflowApprovalModalBase } from "./workflowApprovalModal.base";

const WorkflowApprovalModal = styled<IWorkflowApprovalModalProps, IWorkflowApprovalModalPropsStyles, IWorkflowApprovalModalStyles>(
    WorkflowApprovalModalBase,
    getStyles,
    undefined,
    { scope: 'WorkflowApprovalModal' }
);

export default WorkflowApprovalModal;