import axios from 'axios';
import _ from "lodash";
import teamsAuthService from '../authentication/teamsAuthService';
import webAuthService from '../authentication/webAuthService';
import { environment, isInDebug } from "./config";

interface IProblemDetails {
    type: string;
    title: string;
    status: number;
    detail: string;
    traceId: string;
    subCode?: number;
    errorCodes?: Record<string, string[]>;
}

export class ErrorDetails {
    public code: number;
    public subCode?: number;
    public message: string;
    public detail?: string;
    public errorCodes?: Record<string, string[]>;

    constructor(code: number, message: string, subCode?: number, detail?: string, errorCodes?: Record<string, string[]>) {
        this.code = code;
        this.message = message;
        this.subCode = subCode;
        this.detail = detail;
        this.errorCodes = errorCodes;
    }

    hasValidationError(field: string, code: string) {
        if (!this.errorCodes)
            return false;

        return this.errorCodes[field].some(error => error === code);
    }

    getAllValidationErrorCodes() {
        if (!this.errorCodes)
            return [];

        return _.values(this.errorCodes).flat();
    }
}

const apiClientInstance = axios.create({ baseURL: environment[window.location.host] });

apiClientInstance.interceptors.request.use(async config => {
    config.baseURL = isInDebug(config.baseURL, config.url);

    let result;
    if (window.top !== window.self)
        result = await teamsAuthService.tryGetAccessToken();
    else
        result = await webAuthService.tryGetAccessToken();

    config.headers = { 'Authorization': `Bearer ${(result as any)[1]}` } //eslint-disable-line @typescript-eslint/no-explicit-any
    return config;
});

apiClientInstance.interceptors.response.use(undefined, error => {
    const response = error.response?.data as IProblemDetails;
    if (!response)
        return Promise.reject(error);

    const errorDetails = new ErrorDetails(response.status, response.title, response.subCode, response.detail, response.errorCodes);
    return Promise.reject(errorDetails);
});

export const apiClient = apiClientInstance;