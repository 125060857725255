import { classNamesFunction, Icon, Stack, StackItem, Sticky } from "@fluentui/react";
import React, { useState } from "react";
import { IAccordionProps, IAccordionPropsStyles, IAccordionStyles } from "./accordion.types";

const getClassNames = classNamesFunction<IAccordionPropsStyles, IAccordionStyles>();

export const AccordionBase = (props: IAccordionProps) => {
    const [opened, setOpened] = useState(props.opened ?? false);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, opened: opened });
    
    const onHeaderClick = () => {
        if (!props.clickOnlyIconEnabled)
            setOpened(!opened);
        if (props.additionalOnAccordionClick !== undefined)
            props.additionalOnAccordionClick();
    }

    const header = (
        <Stack horizontal className={classNames.header} onClick={() => onHeaderClick()}>
            {props.isIconHidden ? '' : <Icon styles={classNames.subComponentStyles.icon} onClick={() => props.clickOnlyIconEnabled ? setOpened(!opened) : ''} iconName={opened ? 'FlickUp' : 'FlickLeft'} />}
            <div className={classNames.title}>{props.title}</div>
            {props.customHeader}
        </Stack>
    );

    return (
        <Stack className={classNames.root}>
            {props.stickyHeader ? <Sticky>{header}</Sticky> : header}
            {opened && <StackItem>
                {props.children}
            </StackItem>}
        </Stack>
    );
}