import { IStyleFunction } from "@fluentui/react"
import { ITitleListSectionPropsStyles, ITitleListSectionStyles } from "./titleListSection.types"

export const TitleListSectionGlobalClassNames = {
    titleContainer: 'edi-title-list-section-titleContainer'
}

export const getStyles: IStyleFunction<ITitleListSectionPropsStyles, ITitleListSectionStyles> = (props: ITitleListSectionPropsStyles): ITitleListSectionStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        titleContainer: [TitleListSectionGlobalClassNames.titleContainer, {
            display: 'flex',
            padding: '0px 20px',
            height: '40px',
            fontSize: '18px',
            justifyContent: 'space-between',
            borderTop: `1px solid ${semanticColors.variantBorder}`,
            borderBottom: `1px solid ${semanticColors.variantBorder}`,
            alignItems: 'center',
            fontWeight: 700,
            color: palette.black,
        }],
        subComponentStyles: {
            reloadIconButton: {
                root: {
                    color: palette.black,
                    transform: 'rotate(90deg)'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`,
                    color: palette.themePrimary
                },
                rootExpanded: {
                    backgroundColor: 'transparent',
                    color: palette.themePrimary,
                    textShadow: 'rgb(37 36 35) 0px 0px 1px'
                },
                rootPressed: {
                    backgroundColor: 'transparent',
                },
                rootHovered: {
                    backgroundColor: 'transparent',
                },
                rootDisabled: {
                    backgroundColor: 'transparent'
                }
            }
        }
    };
}