import { IStyleFunction } from "@fluentui/merge-styles";
import { IDatePickerPropsStyles, IDatePickerStyles } from "./datePicker.types";

export const DatePickerGlobalClassNames = {
    root: 'edi-datepicker-root',
}

export const getStyles: IStyleFunction<IDatePickerPropsStyles, IDatePickerStyles> = (props: IDatePickerPropsStyles): IDatePickerStyles => {

    return {
        root: [
            DatePickerGlobalClassNames.root,
            {
                '.ms-TextField-errorMessage': {
                    padding: 0
                }
            },
        ],
        container:{
            display:'flex',
            justifyContent:'space-beetwen',
            alignItems:'baseline'
        }
    }
}