import React from "react";
import { classNamesFunction, IconButton, keyframes } from "@fluentui/react";
import { ITitleListSectionPropsStyles, ITitleListSectionStyles, ITitleListSectionProps } from "./titleListSection.types";

const getClassNames = classNamesFunction<ITitleListSectionPropsStyles, ITitleListSectionStyles>();

export const TitleListSectionBase = (props: ITitleListSectionProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    return (
        <div className={classNames.titleContainer}>
            {props.title}
            {props.refreshButtonHandler &&
                <IconButton
                    styles={classNames.subComponentStyles.reloadIconButton()}
                    style={props.isReloading ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
                    disabled={props.isReloading}
                    iconProps={{ iconName: "Refresh" }}
                    onClick={props.refreshButtonHandler}
                />
            }
        </div>
    );
}