import { styled } from "@fluentui/react";
import { getStyles } from "./setProperties.styles";
import { ISetPropertiesPropsStyles, ISetPropertiesStyles, ISetPropertiesProps } from "./setProperties.types";
import { SetPropertiesBase } from "./setProperties.base";

const SetProperties = styled<ISetPropertiesProps, ISetPropertiesPropsStyles, ISetPropertiesStyles>(
    SetPropertiesBase,
    getStyles,
    undefined,
    { scope: 'SetProperties' }
);

export default SetProperties;