import { styled } from "@fluentui/react";
import { SPOFolderContentBase } from "./SPOfolderContent.base";
import { getStyles } from "./SPOfolderContent.styles";
import { ISPOFolderContentProps, ISPOFolderContentPropsStyles, ISPOFolderContentStyles } from "./SPOfolderContent.types";


const SPOFolderContent = styled<ISPOFolderContentProps, ISPOFolderContentPropsStyles, ISPOFolderContentStyles>(
    SPOFolderContentBase,
    getStyles,
    undefined,
    { scope: 'SPOFolderContent' }
);

export default SPOFolderContent;