import { IStyleFunction } from "@fluentui/react"
import { IInformationPropsStyles, IInformationStyles } from "./information.types"

export const InformationGlobalClassNames = {
    iconWrapper: 'edi-information-iconWrapper',
    successIcon: 'edi-information-successIcon',
    responseContainer: 'edi-information-responseContainer',
    failureIcon: 'edi-information-failureIcon'
}

export const getStyles: IStyleFunction<IInformationPropsStyles, IInformationStyles> = (props: IInformationPropsStyles): IInformationStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        iconWrapper: [
            InformationGlobalClassNames.iconWrapper,
            {
                display: 'flex',
                margin: '0 20px',
                alignItems: 'center',
                cursor: 'pointer',
                fontSize: '20px',
                paddingTop: '3px'
            }
        ],
        responseContainer: [InformationGlobalClassNames.responseContainer, {
            position: 'absolute',
            fontSize: '12px',
            left: '12px',
            bottom: 0
        }],
        successIcon: [InformationGlobalClassNames.successIcon, {
            color: palette.green
        }],
        failureIcon: [InformationGlobalClassNames.failureIcon, {
            color: palette.redDark
        }],
    };
}