import { styled } from "@fluentui/react";
import { getStyles } from "./ediModal.styles";
import { IEdiModalPropsStyles, IEdiModalStyles, IEdiModalProps } from "./ediModal.types";
import { EdiModalBase } from "./ediModal.base";

const EdiModal = styled<IEdiModalProps, IEdiModalPropsStyles, IEdiModalStyles>(
    EdiModalBase,
    getStyles,
    undefined,
    { scope: 'EdiModal' }
);

export default EdiModal;