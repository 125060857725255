import { IStyleFunction } from "@fluentui/react"
import { IFileIconCellPropsStyles, IFileIconCellStyles } from "./fileIconCell.types"

export const FileIconCellGlobalClassNames = {
    root: 'edi-file-icon-cell-root',
    fileIconWrapper: 'edi-file-icon-cell-wrapper',
    fileIconImg: 'edi-file-icon-cell-img'
}

export const getStyles: IStyleFunction<IFileIconCellPropsStyles, IFileIconCellStyles> = (props: IFileIconCellPropsStyles): IFileIconCellStyles => {
    return {
        root: [
            FileIconCellGlobalClassNames.root,
            {

            }
        ],
        fileIconWrapper: [
            FileIconCellGlobalClassNames.fileIconWrapper,
            {
                display: 'flex',
                justifyContent: 'center'
            }
        ],
        fileIconImg: [
            FileIconCellGlobalClassNames.fileIconWrapper,
            {
                verticalAlign: 'middle',
                height: '20px',
                width: '20px'
            }
        ]
    };
}