import React from "react";
import { ITeamsButtonProps, ITeamsButtonPropsStyles, ITeamsButtonStyles } from "./teamsButton.types";
import { classNamesFunction } from "@fluentui/utilities";
import TeamsImage from "../teamsImage/teamsImage";

const getClassNames = classNamesFunction<ITeamsButtonPropsStyles, ITeamsButtonStyles>();

export const TeamsButtonBase = (props: ITeamsButtonProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className,  });

    return (
        <button className={classNames.root} onClick={props.onClick}>
            <TeamsImage imageName={props.imageName} styles={{root: {height: '40px', width: '40px', margin: '32px'}}} />
            <div className={classNames.text}>
                <h3 className={classNames.title}>{props.title}</h3>
                <p className={classNames.subtitle}>{props.subTitle}</p>
            </div>
        </button>
    );
}