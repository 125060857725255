import { FontWeights, IIconStyles, IStyleFunction } from "@fluentui/react"
import { IAccordionPropsStyles, IAccordionStyles } from "./accordion.types"

export const AccordionGlobalClassNames = {
    root: 'edi-accordion-root',
    header: 'edi-accordion-header',
    title: 'edi-accordion-title'
}

export const getStyles: IStyleFunction<IAccordionPropsStyles, IAccordionStyles> = (props: IAccordionPropsStyles): IAccordionStyles => {
    return {
        root: [
            AccordionGlobalClassNames.root,
            {
            }
        ],
        header: [
            AccordionGlobalClassNames.header,
            {
                cursor: 'pointer',
                padding: 10
            }
        ],
        title: [
            AccordionGlobalClassNames.title,
            {
                fontWeight: FontWeights.semibold,
                paddingLeft: 10
            }
        ],
        subComponentStyles: {
            icon: (): IIconStyles => {
                return {
                    root: {
                        fontSize: 7,
                        paddingTop: props.opened ? 8 : 7
                    }
                }
            }
        }
    };
}