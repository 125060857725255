export const it = {
    nations: {
        'ABW': ' Aruba',
        'AFG': ' Afghanistan',
        'AGO': ' Angola',
        'AIA': ' Anguilla',
        'ALA': ' Isole Åland',
        'ALB': ' Albania',
        'AND': ' Andorra',
        'ARE': ' Emirati Arabi Uniti',
        'ARG': ' Argentina',
        'ARM': ' Armenia',
        'ASM': ' Samoa Americane',
        'ATA': ' Antartide',
        'ATF': ' Terre Australi e Antartiche Francesi',
        'ATG': ' Antigua e Barbuda',
        'AUS': ' Australia',
        'AUT': ' Austria',
        'AZE': ' Azerbaigian',
        'BDI': ' Burundi',
        'BEL': ' Belgio',
        'BEN': ' Benin',
        'BES': ' Isole BES',
        'BFA': ' Burkina Faso',
        'BGD': ' Bangladesh',
        'BGR': ' Bulgaria',
        'BHR': ' Bahrein',
        'BHS': ' Bahamas',
        'BIH': ' Bosnia ed Erzegovina',
        'BLM': ' Saint-Barthélemy',
        'BLR': ' Bielorussia',
        'BLZ': ' Belize',
        'BMU': ' Bermuda',
        'BOL': ' Bolivia',
        'BRA': ' Brasile',
        'BRB': ' Barbados',
        'BRN': ' Brunei',
        'BTN': ' Bhutan',
        'BVT': ' Isola Bouvet',
        'BWA': ' Botswana',
        'CAF': ' Repubblica Centrafricana',
        'CAN': ' Canada',
        'CCK': ' Isole Cocos (Keeling)',
        'CHE': ' Svizzera',
        'CHL': ' Cile',
        'CHN': ' Cina',
        'CIV': ' Costa d\'Avorio',
        'CMR': ' Camerun',
        'COD': ' Repubblica Democratica del Congo',
        'COG': ' Repubblica del Congo',
        'COK': ' Isole Cook',
        'COL': ' Colombia',
        'COM': ' Comore',
        'CPV': ' Capo Verde',
        'CRI': ' Costa Rica',
        'CUB': ' Cuba',
        'CUW': ' Curaçao',
        'CXR': ' Isola di Natale',
        'CYM': ' Isole Cayman',
        'CYP': ' Cipro',
        'CZE': ' Repubblica Ceca',
        'DEU': ' Germania',
        'DJI': ' Gibuti',
        'DMA': ' Dominica',
        'DNK': ' Danimarca',
        'DOM': ' Repubblica Dominicana',
        'DZA': ' Algeria',
        'ECU': ' Ecuador',
        'EGY': ' Egitto',
        'ERI': ' Eritrea',
        'ESH': ' Sahara Occidentale',
        'ESP': ' Spagna',
        'EST': ' Estonia',
        'ETH': ' Etiopia',
        'FIN': ' Finlandia',
        'FJI': ' Figi',
        'FLK': ' Isole Falkland',
        'FRA': ' Francia',
        'FRO': ' Fær Øer',
        'FSM': ' Stati Federati di Micronesia',
        'GAB': ' Gabon',
        'GBR': ' Regno Unito',
        'GEO': ' Georgia',
        'GGY': ' Guernsey',
        'GHA': ' Ghana',
        'GIB': ' Gibilterra',
        'GIN': ' Guinea',
        'GLP': ' Guadalupa',
        'GMB': ' Gambia',
        'GNB': ' Guinea-Bissau',
        'GNQ': ' Guinea Equatoriale',
        'GRC': ' Grecia',
        'GRD': ' Grenada',
        'GRL': ' Groenlandia',
        'GTM': ' Guatemala',
        'GUF': ' Guyana francese',
        'GUM': ' Guam',
        'GUY': ' Guyana',
        'HKG': ' Hong Kong',
        'HMD': ' Isole Heard e McDonald',
        'HND': ' Honduras',
        'HRV': ' Croazia',
        'HTI': ' Haiti',
        'HUN': ' Ungheria',
        'IDN': ' Indonesia',
        'IMN': ' Isola di Man',
        'IND': ' India',
        'IOT': ' Territorio britannico dell\'Oceano Indiano',
        'IRL': ' Irlanda',
        'IRN': ' Iran',
        'IRQ': ' Iraq',
        'ISL': ' Islanda',
        'ISR': ' Israele',
        'ITA': ' Italia',
        'JAM': ' Giamaica',
        'JEY': ' Jersey',
        'JOR': ' Giordania',
        'JPN': ' Giappone',
        'KAZ': ' Kazakistan',
        'KEN': ' Kenya',
        'KGZ': ' Kirghizistan',
        'KHM': ' Cambogia',
        'KIR': ' Kiribati',
        'KNA': ' Saint Kitts e Nevis',
        'KOR': ' Corea del Sud',
        'KWT': ' Kuwait',
        'LAO': ' Laos',
        'LBN': ' Libano',
        'LBR': ' Liberia',
        'LBY': ' Libia',
        'LCA': ' Saint Lucia',
        'LIE': ' Liechtenstein',
        'LKA': ' Sri Lanka',
        'LSO': ' Lesotho',
        'LTU': ' Lituania',
        'LUX': ' Lussemburgo',
        'LVA': ' Lettonia',
        'MAC': ' Macao',
        'MAF': ' Saint-Martin',
        'MAR': ' Marocco',
        'MCO': ' Principato di Monaco',
        'MDA': ' Moldavia',
        'MDG': ' Madagascar',
        'MDV': ' Maldive',
        'MEX': ' Messico',
        'MHL': ' Isole Marshall',
        'MKD': ' Macedonia del Nord',
        'MLI': ' Mali',
        'MLT': ' Malta',
        'MMR': ' Birmania',
        'MNE': ' Montenegro',
        'MNG': ' Mongolia',
        'MNP': ' Isole Marianne Settentrionali',
        'MOZ': ' Mozambico',
        'MRT': ' Mauritania',
        'MSR': ' Montserrat',
        'MTQ': ' Martinica',
        'MUS': ' Mauritius',
        'MWI': ' Malawi',
        'MYS': ' Malaysia',
        'MYT': ' Mayotte',
        'NAM': ' Namibia',
        'NCL': ' Nuova Caledonia',
        'NER': ' Niger',
        'NFK': ' Isola Norfolk',
        'NGA': ' Nigeria',
        'NIC': ' Nicaragua',
        'NIU': ' Niue',
        'NLD': ' Paesi Bassi',
        'NOR': ' Norvegia',
        'NPL': ' Nepal',
        'NRU': ' Nauru',
        'NZL': ' Nuova Zelanda',
        'OMN': ' Oman',
        'PAK': ' Pakistan',
        'PAN': ' Panama',
        'PCN': ' Isole Pitcairn',
        'PER': ' Perù',
        'PHL': ' Filippine',
        'PLW': ' Palau',
        'PNG': ' Papua Nuova Guinea',
        'POL': ' Polonia',
        'PRI': ' Porto Rico',
        'PRK': ' Corea del Nord',
        'PRT': ' Portogallo',
        'PRY': ' Paraguay',
        'PSE': ' Stato di Palestina',
        'PYF': ' Polinesia Francese',
        'QAT': ' Qatar',
        'REU': ' Riunione (isola)',
        'ROU': ' Romania',
        'RUS': ' Russia',
        'RWA': ' Ruanda',
        'SAU': ' Arabia Saudita',
        'SDN': ' Sudan',
        'SEN': ' Senegal',
        'SGP': ' Singapore',
        'SGS': ' Georgia del Sud e Isole Sandwich Australi',
        'SHN': ' Sant\'Elena, Ascensione e Tristan da Cunha',
        'SJM': ' Svalbard e Jan Mayen',
        'SLB': ' Isole Salomone',
        'SLE': ' Sierra Leone',
        'SLV': ' El Salvador',
        'SMR': ' San Marino',
        'SOM': ' Somalia',
        'SPM': ' Saint-Pierre e Miquelon',
        'SRB': ' Serbia',
        'SSD': ' Sudan del Sud',
        'STP': ' São Tomé e Príncipe',
        'SUR': ' Suriname',
        'SVK': ' Slovacchia',
        'SVN': ' Slovenia',
        'SWE': ' Svezia',
        'SWZ': ' Swaziland',
        'SXM': ' Sint Maarten',
        'SYC': ' Seychelles',
        'SYR': ' Siria',
        'TCA': ' Turks e Caicos',
        'TCD': ' Ciad',
        'TGO': ' Togo',
        'THA': ' Thailandia',
        'TJK': ' Tagikistan',
        'TKL': ' Tokelau',
        'TKM': ' Turkmenistan',
        'TLS': ' Timor Est',
        'TON': ' Tonga',
        'TTO': ' Trinidad e Tobago',
        'TUN': ' Tunisia',
        'TUR': ' Turchia',
        'TUV': ' Tuvalu',
        'TWN': ' Repubblica di Cina (Taiwan)',
        'TZA': ' Tanzania',
        'UGA': ' Uganda',
        'UKR': ' Ucraina',
        'UMI': ' Isole minori esterne degli Stati Uniti d\'America',
        'URY': ' Uruguay',
        'USA': ' Stati Uniti d\'America',
        'UZB': ' Uzbekistan',
        'VAT': ' Città del Vaticano',
        'VCT': ' Saint Vincent e Grenadine',
        'VEN': ' Venezuela',
        'VGB': ' Isole Vergini britanniche',
        'VIR': ' Isole Vergini americane',
        'VNM': ' Vietnam',
        'VUT': ' Vanuatu',
        'WLF': ' Wallis e Futuna',
        'WSM': ' Samoa',
        'YEM': ' Yemen',
        'ZAF': ' Sudafrica',
        'ZMB': ' Zambia',
        'ZWE': ' Zimbabwe'
    }
}