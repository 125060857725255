import { noop } from "lodash";
import React, { useMemo, useState } from "react";
import EdiModal from "../ediModal/ediModal";
import MultiStep from "../multiStep/multiStep";
import { IMultiStepModalProps } from "./multiStepModal.types";

type InternalStepData = {
    bodyOnly: boolean;
    hideHeader: boolean;
    showCloseIcon: boolean;
}

export const MultiStepModalBase = (props: IMultiStepModalProps) => {
    const callbacks = { onStepChange: noop, onStepEnter: noop, onStepExit: noop, ...props };

    const [currentStepData, setCurrentStepData] = useState<InternalStepData>({
        bodyOnly: props.steps[props.activeStep]?.bodyOnly ?? false,
        hideHeader: props.steps[props.activeStep]?.hideHeader ?? false,
        showCloseIcon: props.steps[props.activeStep]?.showCloseIcon ?? false
    });

    const bodies = useMemo(() => props.steps.map(step => ({ content: step.body })), [props.steps]);

    return (
        <EdiModal {...props}
            styles={props.styles}
            bodyClassname={props.bodyClassname}
            title={props.title ?? props.steps[props.activeStep].title}
            subtitle={props.subtitle ?? props.steps[props.activeStep].subtitle}
            footer={props.footer ?? props.steps[props.activeStep].footer}
            isBlocking={props.isBlocking}
            bodyOnly={props.bodyOnly !== undefined ? props.bodyOnly : currentStepData.bodyOnly}
            hideHeader={props.hideHeader !== undefined ? props.hideHeader : currentStepData.hideHeader}
            showCloseIcon={props.showCloseIcon !== undefined ? props.showCloseIcon : currentStepData.showCloseIcon}        
            body={<MultiStep
                activeStep={props.activeStep}
                steps={bodies}
                animateInitialStep={props.animateInitialStep}
                onStepChange={callbacks.onStepChange}
                onStepExit={callbacks.onStepExit}
                hideScrollbar={props.hideScrollbarBody}
                onStepEnter={(isMutating) => {
                    isMutating && setCurrentStepData({
                        bodyOnly: props.steps[props.activeStep]?.bodyOnly ?? false,
                        hideHeader: props.steps[props.activeStep]?.hideHeader ?? false,
                        showCloseIcon: props.steps[props.activeStep]?.showCloseIcon ?? false
                    });

                    callbacks.onStepEnter(isMutating);
                }}
            />}
        />
    );
}