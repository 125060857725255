import { styled } from "@fluentui/react";
import { getStyles } from "./iconTag.styles";
import { IIconTagPropsStyles, IIconTagStyles, IIconTagProps } from "./iconTag.types";
import { IconTagBase } from "./iconTag.base";

const IconTag = styled<IIconTagProps, IIconTagPropsStyles, IIconTagStyles>(
    IconTagBase,
    getStyles,
    undefined,
    { scope: 'IconTag' }
);

export default IconTag;