import { FontWeights, IStyleFunction } from "@fluentui/react"
import { ITeamsImagePropsStyles, ITeamsImageStyles } from "./teamsImage.types"

export const TeamsImageGlobalClassNames = {
    root: 'edi-teams-image-root',
    img: 'edi-teams-image-img',
    caption: 'edi-teams-image-caption',
}

export const getStyles: IStyleFunction<ITeamsImagePropsStyles, ITeamsImageStyles> = (props: ITeamsImagePropsStyles): ITeamsImageStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            TeamsImageGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            },
            props.fullContainer && {
                width: '100%',
                height: '100%'
            }
        ],
        img: [
            TeamsImageGlobalClassNames.img,
            {
                width: `${100 * props.scale}%`,
                height: `${100 * props.scale}%`,
            }
        ],
        caption: [
            TeamsImageGlobalClassNames.caption,
            {
                paddingTop: 10,
                fontWeight: FontWeights.semilight,
                color: palette.black
            }
        ]
    };
}