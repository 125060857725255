import { IStyleFunction } from "@fluentui/merge-styles";
import { IPeoplePickerCustomPropsStyles, IPeoplePickerCustomStyles } from "./peoplePicker.types";

export const peoplePickerGlobalClassNames = {
    root: '',
}

export const getStyles: IStyleFunction<IPeoplePickerCustomPropsStyles, IPeoplePickerCustomStyles> = (props: IPeoplePickerCustomPropsStyles): IPeoplePickerCustomStyles => {

    return {
        root: []
    }
}