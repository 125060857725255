import { styled } from "@fluentui/react";
import { PeoplePickerBase } from "./peoplePicker.base";
import { getStyles } from "./peoplePicker.style";
import { IPeoplePickerCustomProps, IPeoplePickerCustomPropsStyles, IPeoplePickerCustomStyles } from "./peoplePicker.types";

const PeoplePicker = styled<IPeoplePickerCustomProps, IPeoplePickerCustomPropsStyles, IPeoplePickerCustomStyles>(
    PeoplePickerBase,
    getStyles,
    undefined,
    { scope: 'PeoplePicker' }
);

export default PeoplePicker;