import { styled } from "@fluentui/react";
import { getStyles } from "./fileUploader.styles";
import { IFileUploaderPropsStyles, IFileUploaderStyles, IFileUploaderProps } from "./fileUploader.types";
import { FileUploaderBase } from "./fileUploader.base";

const FileUploader = styled<IFileUploaderProps, IFileUploaderPropsStyles, IFileUploaderStyles>(
    FileUploaderBase,
    getStyles,
    undefined,
    { scope: 'FileUploader' }
);

export default FileUploader;