import { styled } from "@fluentui/react";
import { TeamsBase } from "./teams.base";
import { getStyles } from "./teams.styles";
import { ITeamsProps, ITeamsPropsStyles, ITeamsStyles } from "./teams.types";

const Teams = styled<ITeamsProps, ITeamsPropsStyles, ITeamsStyles>(
    TeamsBase,
    getStyles,
    undefined,
    { scope: 'Teams' }
);

export default Teams;