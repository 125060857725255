import { styled } from "@fluentui/react";
import { getStyles } from "./navigationContainer.styles";
import { INavigationContainerPropsStyles, INavigationContainerStyles, INavigationContainerProps } from "./navigationContainer.types";
import { NavigationContainerBase } from "./navigationContainer.base";

const NavigationContainer = styled<INavigationContainerProps, INavigationContainerPropsStyles, INavigationContainerStyles>(
    NavigationContainerBase,
    getStyles,
    undefined,
    { scope: 'NavigationContainer' }
);

export default NavigationContainer;